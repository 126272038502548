import React, { ReactNode } from 'react';
import { ImageCarousel } from '@naf/image-carousel';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import ResponsiveImage from '../../../../image/ResponsiveImage';
import utils from '../../../../../utils';
import { useMediaQuery } from '../../../../MembershipBox/utils';

interface ImageCarouselSerializerProps {
  value: { images: CloudinaryImageType[] };
}

const ImageCarouselImage = ({ image: { publicId, caption, altText } }: { image: CloudinaryImageType }) => (
  <ResponsiveImage imageId={publicId} altText={altText || caption} />
);

const ImageCarouselBlock = ({ value: { images } }: ImageCarouselSerializerProps) => {
  const isMobile = useMediaQuery('(max-width: 576px)');
  return (
    <ImageCarousel
      width={isMobile ? '350px' : '650px'}
      images={images.map((image) => ({
        image: (<ImageCarouselImage image={image} />) as ReactNode,
        description: `${image?.caption ? `${image?.caption}\n` : ''}${utils.getPhotographerAndCopyrightCaption({
          copyright: image?.copyright,
          photographer: image?.photographer,
        })}`,
      }))}
    />
  );
};

export const ImageCarouselSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ImageCarouselSerializerProps) => (
      <ArticleBlockContentGridCol>
        <ImageCarouselBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: ImageCarouselSerializerProps) => <ImageCarouselBlock value={value} />;
};
