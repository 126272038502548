import { useLocation } from 'react-router';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';

export const useTrackCTA = () => {
  const location = useLocation();
  const dataLayer = useGTMDataLayer();

  const trackCTA = ({ cta_position, text }) => {
    const urlObject = new URL(window.location.href);

    dataLayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_position,
      cta_location: location.pathname,
      cta_url: window.location.href,
      cta_hostname: urlObject.hostname,
    });
  };

  return trackCTA;
};
