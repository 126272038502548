import styled, { css } from 'styled-components';
import { AdvancedImage } from '@cloudinary/react';
import { Text } from '@naf/text';
import { radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

export const Container = styled.div<{ $variant: string }>`
  display: flex;
  flex-direction: column;
  padding: ${spacing.space40} ${spacing.space24};
  max-width: 353px;
  border: 1px solid ${nafColor.neutral.neutral1};
  border-radius: ${radius.s};

  ${({ $variant }) =>
    $variant === 'large' &&
    css`
      background-color: ${nafColor.primary.dew};
    `}

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      background-color: ${nafColor.signature.white};
      box-shadow: 0 ${spacing.space4} ${spacing.space8} ${nafColor.signature.black}33;
    `}
`;

export const SectionTitle = styled(Text)``;

export const HeaderSection = styled.div`
  min-height: 160px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space24};
`;

export const Header = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space16};
`;

export const Bullets = styled.div`
  margin-bottom: ${spacing.space24};

  ul {
    margin-bottom: ${spacing.space12};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledButtonLink = styled.a`
  margin-top: ${spacing.space24};
  text-decoration: none;
  color: ${nafColor.primary.spruce};
  padding: ${spacing.space4};
  border-bottom: solid 3px ${nafColor.primary.park};
  font-weight: 500;
  height: fit-content;

  &:hover {
    border-bottom-color: ${nafColor.primary.spruce};
  }
`;

export const StyledAdvancedImage = styled(AdvancedImage)`
  display: flex;
  align-self: center;
  min-height: 100px;
  max-width: 305px;
  height: 183px;
  object-fit: cover;
`;

export const LargeIllustration = styled.div<{ $variant: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing.space48};

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      display: none;
    `}
`;

export const SmallIllustration = styled.div<{ $variant: string }>`
  display: none;

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 96px;
        max-height: 91px;
      }
    `}
`;

export const BodyContent = styled.div<{ $variant: string }>`
  display: ${({ $variant }) => ($variant === 'large' ? 'block' : 'none')};
  margin-bottom: ${spacing.space24};
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
`;
