import React from 'react';
import { TextVariant } from '@naf/text';
import { SanityBlock } from '@sanity/client';
import { CloudinaryImageType } from '../../../../types/blockTypes';
import { useCloudinary } from '../../hooks/useCloudinary';
import BlockContent from '../block-content/BlockContent';
import * as S from './Styles';
import { MembershipBenefitHighlightLink } from './BenefitHighlightLink';
import { MembershipBenefitHighlightLinkType } from '../../../../types/MembershipBenefitHighlightLinkType';

export enum SanityHighlightVariant {
  Large = 'big',
  Small = 'small',
}

export enum BenefitHighlightVariant {
  Large = 'large',
  Small = 'small',
}

const mapVariant = (variant: string) => {
  switch (variant) {
    case SanityHighlightVariant.Large:
      return BenefitHighlightVariant.Large;
    default:
      return BenefitHighlightVariant.Small;
  }
};

export interface BenefitHighlightProps {
  title?: string;
  description?: string;
  image?: CloudinaryImageType;
  body?: SanityBlock[];
  link?: MembershipBenefitHighlightLinkType;
  variant?: string;
}

export const BenefitHighlight = ({ title, description, image, body, link, variant }: BenefitHighlightProps) => {
  const cld = useCloudinary();
  const itemImage = image?.publicId && cld.image(image.publicId);

  return (
    <S.Container $variant={mapVariant(variant)}>
      <S.HeaderSection>
        {title && <S.Title variant={TextVariant.SubHeader}>{title}</S.Title>}
        {description && <S.Header variant={TextVariant.Header2}>{description}</S.Header>}
      </S.HeaderSection>
      {image && variant === 'big' ? (
        <S.LargeIllustration $variant={mapVariant(variant)}>
          <S.StyledAdvancedImage cldImg={itemImage} alt={image?.altText || ''} />
        </S.LargeIllustration>
      ) : null}
      {body && (
        <S.BodyContent $variant={mapVariant(variant)}>
          <BlockContent value={body} />
        </S.BodyContent>
      )}
      <S.BottomContent>
        {link?.data && <MembershipBenefitHighlightLink link={link} />}
        {image && variant === 'small' ? (
          <S.SmallIllustration $variant={mapVariant(variant)}>
            <S.StyledAdvancedImage cldImg={itemImage} alt={image?.altText || ''} />
          </S.SmallIllustration>
        ) : null}
      </S.BottomContent>
    </S.Container>
  );
};
