import React from 'react';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { InternalMainEntry } from '../../../../../../types/InternalMainEntryType';
import { ExternalMainEntry } from '../../../../../../types/ExternalMainEntryType';

export interface ExternalMainEntryCardProps {
  externalMainEntry: ExternalMainEntry;
}

export interface InternalMainEntryCardProps {
  internalMainEntry: InternalMainEntry;
}

export const ExternalMainEntryCard = ({ externalMainEntry: { image, data } }: ExternalMainEntryCardProps) => {
  const cld = useCloudinary();

  const mainImage =
    (image && cld.image(image.publicId).delivery(dpr('3.0')).quality('auto:best').format('svg')) || undefined;

  return (
    <StyledA href={data.href}>
      <Card>
        {mainImage && (
          <ImageWrapper>
            <AdvancedImage
              cldImg={mainImage}
              plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), responsive({ steps: 200 })]}
              alt={image.altText || image.caption}
            />
          </ImageWrapper>
        )}
        <Label>
          <StyledText variant={TextVariant.Header3}>
            <UnderlinedElement>{data.title}</UnderlinedElement>
          </StyledText>
        </Label>
      </Card>
    </StyledA>
  );
};

export const InternalMainEntryCard = ({ internalMainEntry: { image, data } }: InternalMainEntryCardProps) => {
  const url = useDocumentUrlWithNode(data) || '';

  const cld = useCloudinary();

  const mainImage =
    (image && cld.image(image.publicId).delivery(dpr('3.0')).quality('auto:best').format('svg')) || undefined;

  return (
    <StyledLink to={url}>
      <Card>
        {mainImage && (
          <ImageWrapper>
            <AdvancedImage
              cldImg={mainImage}
              alt={image.altText || image.caption}
              plugins={[responsive({ steps: 200 }), lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
            />
          </ImageWrapper>
        )}
        <Label>
          <StyledText variant={TextVariant.CardHeader}>
            <UnderlinedElement>{data.name}</UnderlinedElement>
          </StyledText>
        </Label>
      </Card>
    </StyledLink>
  );
};

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  padding: ${spacing.space20} 0 0 0;
  flex: 1 1 0;
  width: 104px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  border-radius: 52px;
`;

const UnderlinedElement = styled.span`
  background-size: 0 2px;
  background-position: 0 100%;
  background-image: linear-gradient(90deg, #ffdb16, #ffdb16);
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.3s ease;
  transition: background-size 0.3s ease;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline;
`;

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;

  // Create padding to display focus line
  padding: 0 ${spacing.space20};

  * img {
    max-width: 120px;
    transition: transform 0.3s ease;
    @media (min-width: ${breakpoints.m}) {
      align-self: center;
    }
  }

  &:hover {
    * img {
      transform: scale(1.1);
    }
  }

  :hover {
    color: inherit;
  }
  &:hover ${UnderlinedElement}, &:active ${UnderlinedElement} {
    background-size: 100% 2px;
  }
  &:active {
    outline: none !important;
  }
`;

const StyledA = styled.a`
  display: flex;
  text-decoration: none;

  // Create padding to display focus line
  padding: 0 ${spacing.space20};

  * img {
    max-width: 120px;
    transition: transform 0.3s ease;
    @media (min-width: ${breakpoints.m}) {
      align-self: center;
    }
  }

  &:hover {
    color: ${nafColor.signature.black};
    * img {
      transform: scale(1.1);
    }
  }
  &:hover ${UnderlinedElement}, &:active ${UnderlinedElement} {
    background-size: 100% 2px;
  }
  &:active {
    outline: none !important;
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  width: 132px;
`;

const StyledText = styled(Text)`
  margin-top: ${spacing.space12};
  margin-bottom: 0;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5;

  @media (min-width: ${breakpoints.l}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space8};
  }
`;
