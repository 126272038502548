import React from 'react';
import { Button, ButtonVariant } from '@naf/button';
import { Link, useLocation } from 'react-router-dom';
import { ExternalLinkType } from '../../../../types/externalLinkType';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import * as S from './Styles';
import { ButtonType } from '../../../../types/ModularButtonBlockType';

interface ModularButtonProps {
  primaryButton: ButtonType & { variant?: string };
  secondaryButton?: ButtonType;
}

export const ModularButton = ({ primaryButton, secondaryButton }: ModularButtonProps) => {
  const primaryInternalLink = useDocumentUrlWithNode(primaryButton?.link?.data);
  const secondaryInternalLink = useDocumentUrlWithNode(secondaryButton?.link?.data);
  const primaryButtonExternalLink = {
    ...(primaryButton.link?.data || primaryButton.externalLink),
    blank: primaryButton.link?.data?.blank ?? false,
    key: primaryButton.link?.data?.href,
  } as ExternalLinkType & { style?: any };
  const secondaryButtonExternalLink = {
    ...(secondaryButton?.link?.data || secondaryButton?.externalLink),
    blank: secondaryButton?.link?.data?.blank ?? false,
    key: secondaryButton?.link?.data?.href,
  } as ExternalLinkType & { style?: any };
  const datalayer = useGTMDataLayer();
  const location = useLocation();

  const handleTracking = (text: string, path: string) => {
    datalayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: location.pathname,
      cta_url: path,
      cta_position: 'content',
    });
  };

  const renderButton = (
    text: string,
    variant: string,
    internalLink?: string,
    externalLink?: ExternalLinkType & { style?: any },
  ) => {
    if (!text) return null;

    if (internalLink) {
      return (
        <Link to={internalLink}>
          <Button variant={variant as ButtonVariant} type="button" onClick={() => handleTracking(text, internalLink)}>
            {text}
          </Button>
        </Link>
      );
    }

    if (externalLink) {
      return (
        <Button
          variant={variant as ButtonVariant}
          type="button"
          href={externalLink.href}
          target={externalLink.blank ? '_blank' : '_self'}
          onClick={() => handleTracking(text, externalLink.href)}
          style={externalLink.style}
        >
          {text}
        </Button>
      );
    }

    return null;
  };

  return (
    <S.ButtonContainer>
      {renderButton(
        primaryButton.buttonText,
        primaryButton.variant || 'signature',
        primaryInternalLink,
        primaryButtonExternalLink,
      )}
      {secondaryButton &&
        secondaryButton.buttonText &&
        renderButton(secondaryButton.buttonText, 'outline', secondaryInternalLink, secondaryButtonExternalLink)}
    </S.ButtonContainer>
  );
};
