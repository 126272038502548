import { useLocation } from 'react-router-dom';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';
import useCloseMenu from '../../../hooks/useCloseMenu';

export const useHandleBecomeMemberClick = () => {
  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const closeMenu = useCloseMenu();

  return () => {
    dataLayer?.push({
      event: 'cta_click',
      menuPage: location.pathname,
      menuItemName: 'Bli medlem',
      menuItemUrl: '/medlemskap',
      cta_position: 'topbar',
    });
    closeMenu();
  };
};

export const useHandleBookAppointmentClick = () => {
  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const closeMenu = useCloseMenu();

  return () => {
    dataLayer?.push({
      event: 'cta_click',
      menuPage: location.pathname,
      menuItemName: 'Bestill time',
      menuItemUrl: '/bilverksted-og-tester',
      cta_position: 'topbar',
    });
    closeMenu();
  };
};

export const useHandleNavigationClick = ({
  event,
  menuSectionName,
  menuItemUrl,
  menuItemName,
  menuPage,
  onClickAction,
}: {
  event?: string;
  menuSectionName?: string;
  menuItemUrl?: string;
  menuItemName?: string;
  menuPage?: string;
  onClickAction?: () => void;
}) => {
  const dataLayer = useGTMDataLayer();
  const closeMenu = useCloseMenu();

  return () => {
    const data = {
      event,
      menuSectionName,
      menuItemUrl,
      menuItemName,
      menuPage,
    };
    dataLayer?.push(data);
    closeMenu();

    if (onClickAction) {
      onClickAction();
    }
  };
};
